import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>


    <p>{`Anyone can record the screen easily.`}</p>
    <p>{`If you prefer not to download any additional software to record your screen, chances are you already have a program that can do it for you. Luckily, if you're on Mac, you can simply open QuickTime Player. On Windows you'll need PowerPoint, or other download alternative (free) software - but don't worry, this is easy.`}</p>
    <h2>{`On Mac OS`}</h2>
    <p><strong parentName="p">{`Step 1:`}</strong>{` Open QuickTime Player (installed on every mac), and from the top menu bar, click `}{`[`}{`keybt`}{`]`}{`New Screen Recording ⌃⌘N`}{`[`}{`/keybt`}{`]`}{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "846px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/13355c09977962eeed4ed1d4e6bd4863/5b481/img_5b978c400c948.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABR0lEQVQY0yWOvW7TUABG87A8AgM7EupCJSZWNgYGUCGZSAmQqHHt1HbcxPg6/g2247S2771xipgOShg+nW86OoPP4xleVDD6Nj9vfGNzPXf4cj1jagtefOh59u4vI3PHMoiIdwpjKYgriRNkfL/1cMWWqGyZmvcMoiQlybaITcwmThGbCD8IWfnBmW5YM7E2WK6Ps1yxDkJu71y89S+ce5+8rMmKHcbCZh1EDPq+R0rJn6cntFL8zreEgSCJE7Z5TllVmAsbw3KYzi1+3FhMjQU/5zaTmUlWNuRFzXgyYy0SBlprTtK27eik5EE9Und7Gt1QP+559XHPxZXi9bDjcii5HHb//0jyZtTy8tOB5+8PvP2quLjSp8Ijx+ORk1hpRdVUFA/FmSeh6Ze4cYuXtNjhnlWmWKYaLz3gpT13kcYQByzRYIWSfw+jX8+Ned3UAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img 5b978c400c948",
            "title": "img 5b978c400c948",
            "src": "/static/13355c09977962eeed4ed1d4e6bd4863/5b481/img_5b978c400c948.png",
            "srcSet": ["/static/13355c09977962eeed4ed1d4e6bd4863/5a46d/img_5b978c400c948.png 300w", "/static/13355c09977962eeed4ed1d4e6bd4863/0a47e/img_5b978c400c948.png 600w", "/static/13355c09977962eeed4ed1d4e6bd4863/5b481/img_5b978c400c948.png 846w"],
            "sizes": "(max-width: 846px) 100vw, 846px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 2:`}</strong>{` Ensure you use the `}<strong parentName="p">{`Internal Microphone`}</strong>{` (then your voice may be heard). Optionally, you may also tick "Show Mouse Clicks in Recording".`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "778px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/967fbe6fa3446bb6efdc891c91db486b/20982/img_5b978c3481f12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACp0lEQVQoz0XSXU9SARjA8fMVaq20SAeCSKRhCpqpGCBx4CAhihg0PIqGoJWpF603UaxWa+vG9brVpql1kV1UbrX6DGbaG/UBKJuJXnj3b56tuvjt2XPx/K8eod1iJuo1UVtrQVtsoMJsRvR48DY1EWhpoa2tjePhMJLXi8PhoNHpxGa34xJFFhYW2NjYIJfLsb6+rkzBoDdgNBoo1pdgMJZSUWmm3tqgHIpuNz7fMfx+vzL/7s3NAZqafLx9946fP1fIZrNksz9YXV1FKCrWo9Ub0Gh1GPYZMR2swFJVTb3VisN5lKMuF263B0mS/gcDLbg9EjOzsywtf1R8WFpmZeUXglqjRa0pUoJanV6JlpYdQHSJPLt7m2AwSH29lSM22z8NNhv2RieTU1O8X1zk8+cvfM1kyGQyCFshTZFOCer0JRhLyzCVl1NTcxg5EsFmd+B0iUheHx7Ji1uScIlufP5mWkMhItEO5Fg3XSfjdCcSCPvLTKgK1ezKV7FbVcDOvD1UWqoYHBzk9MAA7W3N+CUHybhMtxwmcEykvdVLMODhxHE/PZ0hEj0R+nqj9Cc6EEwVVRSodeSrClEVaNi2Iw+708XrN2+YnZ6i9/wE9tgEifQTkukn9F95ysCNObovPkQ+/4jIuUmiFx4jX5xGvjSDYGsUUesM7N6rIU9dwvb8AlqC7Xz79p1Py+8ZuzOPNDRHZ+o5sbEtc/SMP6f/+gviV18SHX2FnH5FZ3qervF5hMNWOw0OF4fqbCQcNZj1xbSGO1hb+81aLsetm9c4KYcYHkhwJhkj0ROlL95Bf6/MmaTM0Kkuhk/HFGf7ZITq2ga2VFbXEa6zcECrJXSiU3nYzc1N7t1/QLw3SWo0zeVUSjGSSjE6NsblkRFSoynS42nF0PAQfwCApbUxfMyCMwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img 5b978c3481f12",
            "title": "img 5b978c3481f12",
            "src": "/static/967fbe6fa3446bb6efdc891c91db486b/20982/img_5b978c3481f12.png",
            "srcSet": ["/static/967fbe6fa3446bb6efdc891c91db486b/5a46d/img_5b978c3481f12.png 300w", "/static/967fbe6fa3446bb6efdc891c91db486b/0a47e/img_5b978c3481f12.png 600w", "/static/967fbe6fa3446bb6efdc891c91db486b/20982/img_5b978c3481f12.png 778w"],
            "sizes": "(max-width: 778px) 100vw, 778px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 3:`}</strong>{` You're ready to record your screen. Click the big red recording button. You'll be able to choose whether you wish to record you whole screen, or only the part of the screen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "580px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f9e2490c4d0c69d4fa0c9ba46f27e27e/b6272/img_5b978eea6f8b0.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABg0lEQVQozz2Ry27TQABF/SWI+BGnseMmtXETHJLG9rySpnngZIFoyoZ/AAl23RQhJD6AHf95kIe0i6sZzeLMubpOk2V8mmZ8/jAiz68ZJEP8bsjsZoFUmrvNhu1ux/F4pDk0fDzd0zQNSimkFEglzxEoJXCKKGYxjKnnEYPkkot+TNANmRQFi7JCL5doY9hut+z2ewttgRbSAqWgrEpqUSFkjfPKC3B7fdwgouP59PoR+fWYfDxh+m5GWVVobVitVmw2G5sW3kLX61sOh4aHhxOn0z379zsc1w+sURCGeH6XIOzRj2IuhyPe5DnFdMp0NuemqhFCoLVGG40xBqUVZmlY361Z3a4wRuG0Vh3Xt3Yt3A9Cur0LonhAmmYkVylP37/x9/cvpDEWamPr/q9di/az2t6dZ1inrX4Ghr0+8SAhzTKGacbj1y/8+fkDqQ1SnEFK2mHUyyjSvjuvXY9nS9cL8IIuYWs4SBhdpYzHE8ZvC4r5nLIsEVKegcqeSqqXgVrrf80f80KfRxE5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img 5b978eea6f8b0",
            "title": "img 5b978eea6f8b0",
            "src": "/static/f9e2490c4d0c69d4fa0c9ba46f27e27e/b6272/img_5b978eea6f8b0.png",
            "srcSet": ["/static/f9e2490c4d0c69d4fa0c9ba46f27e27e/5a46d/img_5b978eea6f8b0.png 300w", "/static/f9e2490c4d0c69d4fa0c9ba46f27e27e/b6272/img_5b978eea6f8b0.png 580w"],
            "sizes": "(max-width: 580px) 100vw, 580px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 4:`}</strong>{` End the recording by clicking the stop button in the menu bar.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "272px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eb7237e94d2ef2f6a4bd9bf7cda971aa/2aae8/img_5b978cf7caa47.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABb0lEQVQoz32Q207CQBCG97FMvJBrE1GO1gSkLd12t1AKbbfdUsV44XP/ZgYWiSFcfJl/jpkZUTZ7lE13k8p+YFtZBFIj3RTsUzzbGURqhfW2QtN9IdYZhLEfMC3xeRVqrvcH6GyHu/sHTN7m3JyXDd7mPvrDKQKpcPj+wePTACIva07egrZLNyW8mY9lsmI/K2pEao0wTrHKS+xMC7XKIeJEQRJxckJd8BeLE41Ep0iUPuYSxTpRKeeojnxhrQXRtu0Flum6Dt1+j/ZcY1k7/xIXE03TgDDGMFVVoa5rtsY4a7imdPrUTDGqpSXcHLGMIoRhAKUU8u2WC1jn+Xl4mqbo9/usQ9/HcDDAbDZHJCUWiwU8z0MYhpi+ehDj8QRRFEFrhWyz4Q201jyQNA2RUqLX66EyBrGUGI1GGLwM8b7wEQQBXp6fOTadvkJQg1vdnexeQLooivPJx40bPpFOdr22dbr++6HD/ea/vuZf4xeZ5sxNlY3qjQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img 5b978cf7caa47",
            "title": "img 5b978cf7caa47",
            "src": "/static/eb7237e94d2ef2f6a4bd9bf7cda971aa/2aae8/img_5b978cf7caa47.png",
            "srcSet": ["/static/eb7237e94d2ef2f6a4bd9bf7cda971aa/2aae8/img_5b978cf7caa47.png 272w"],
            "sizes": "(max-width: 272px) 100vw, 272px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`On Windows`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1e1de2a92df141758027dbde895ced69/37523/img_5b978f836da90.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABzElEQVQoz22O204TURiF501oMHrlI9g26o0v5r16XyOYCqQhxR6iJCbcIKEJQaTQlk6hhzmUdpzZc+q0hXQ+090C0bCTL+v/19p7ZSvldIJiKkExmaCQfNBSaumnEhRerFBKr1J++YRielVSmJNcofj6GaU3z6UWXz1FOdpcp7qV4Xz7M/V8llo+S2PnC5WNdaq5Nc5yn2jsZPm1keH4w1v07TXUj++42MrQLGzSLOdQd/NS57tyYVjowyGG7WA6Av2PQ99xqXYHXDsuAyHoO4KWqnJ5cozmjbCGQ0KtQziDcDJdML0hvLlF6XV11OaVpFFXMY0B132LbltD65nYlsDsW/Q1g96PXc5rKp3TM9yLJsKLELaHcHyJY3sovvjJyD9gHFbumYQVfLGPPdwjCg6ZjI8Irr7h7b0nauQZtb4ycveJggrj4HBBWCFwD1ACr4Y/xz3/h7kfjpr4ThWv/h2vfUAQtfDdGr5fx/fqCPsU2zrBtn5LrMEJShzDowCzaMptp80sHMs9nj3k8yNEQE/rYxgDdH0gVXm8bSYfxFFIfDtdzNJb5PEyd12BrmuYpnGPEssL/yELlt9YznfeHYtCF13XMU0TwzCk/gW2a3kZ0aBQGQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "img 5b978f836da90",
            "title": "img 5b978f836da90",
            "src": "/static/1e1de2a92df141758027dbde895ced69/37523/img_5b978f836da90.png",
            "srcSet": ["/static/1e1de2a92df141758027dbde895ced69/5a46d/img_5b978f836da90.png 300w", "/static/1e1de2a92df141758027dbde895ced69/0a47e/img_5b978f836da90.png 600w", "/static/1e1de2a92df141758027dbde895ced69/37523/img_5b978f836da90.png 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The latest versions of PowerPoint include the feature to make a Screen Recording.`}</p>
    <h2>{`How to record your screen using PowerPoint on Windows`}</h2>
    <p><strong parentName="p">{`Step 1:`}</strong>{` Go to the “Insert” tab, and select “Screen Recording.”`}</p>
    <p><strong parentName="p">{`Step 2:`}</strong>{` Choose “Select Area” to choose the specific area of your screen you want to record. If you want to record the entire screen, press `}<kbd>{`Windows Key`}</kbd>{` + `}<kbd>{`Shift`}</kbd>{` + `}<kbd>{`F`}</kbd></p>
    <p><strong parentName="p">{`Step 3:`}</strong>{` Click the “record” button,” or press `}<kbd>{`Windows Key`}</kbd>{` + `}<kbd>{`Shift`}</kbd>{` + `}<kbd>{`R`}</kbd></p>
    <p>{`When you’re done you can save the video as a separate file to access or embed as you see fit.`}</p>
    <h2>{`Don't have PowerPoint? Check out these alternative apps`}</h2>
    <p>{`If you don't have PowerPoint check out `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://obsproject.com/download"
        }}>{`OBS Studio (free)`}</a></strong>{` or `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.techsmith.com/store/snagit"
        }}>{`Snagit (paid)`}</a></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      